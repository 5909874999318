import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const MapLegend = () => (
  <Card className="absolute w-auto flex flex-col gap-5 px-6 py-5 right-10 top-10 z-30 text-sm">
    <div className="flex items-center gap-2">
      <span className="w-4 h-4 rounded-full border-2 border-solid border-danger-600 bg-danger-600 bg-opacity-20" />
      <FormattedMessage {...messages.restriction} />
    </div>
    <div className="flex items-center gap-2">
      <span className="w-4 h-4 rounded-full border-2 border-solid border-primary-400 bg-primary-400 bg-opacity-20" />
      <FormattedMessage {...messages.bonusZone} />
    </div>
  </Card>
);
