import { defineMessages } from 'react-intl';

export default defineMessages({
  active: {
    defaultMessage: 'Active',
  },
  inactive: {
    defaultMessage: 'Inactive',
  },
  disablingSuccess: {
    defaultMessage: 'The restriction was successfully disabled',
  },
  enablingSuccess: {
    defaultMessage: 'The restriction was successfully enabled',
  },
  collisionError: {
    defaultMessage: 'The restriction collides with an active bonus zone',
  },
});
