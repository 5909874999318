import { defineMessages } from 'react-intl';

import { TranslatedRejectReason } from './types';

export default defineMessages({
  rejectHeading: {
    defaultMessage: 'The user will be rejected for the following reasons',
  },
  rejectButton: {
    defaultMessage: 'Reject',
  },
});

export const rejectReasonMessages = defineMessages<TranslatedRejectReason>({
  fraud: {
    defaultMessage: 'Fraud',
  },
  minor: {
    defaultMessage: 'Minor',
  },
  DUPLICATED_ID_NUMBER: {
    defaultMessage: 'Identity Card Number has already been taken',
  },
});
