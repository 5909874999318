import { System, WorkShiftsSort } from '@cooltra/api';

export type ShiftsFiltersFormValues = {
  system: System[];
  minDate: string;
  maxDate: string;
  operatorId: string;
  sort: WorkShiftsSort;
};

export const shiftsFiltersInitialValues: ShiftsFiltersFormValues = {
  system: [],
  minDate: '',
  maxDate: '',
  operatorId: '',
  sort: 'createdAt.desc',
};
