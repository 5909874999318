import { defineMessages } from 'react-intl';
import { System } from '@cooltra/api';

export const cities = defineMessages<System>({
  Barcelona: {
    defaultMessage: 'Barcelona',
  },
  Berlin: {
    defaultMessage: 'Berlin',
  },
  Munich: {
    defaultMessage: 'Munich',
  },
  Lisbon: {
    defaultMessage: 'Lisbon',
  },
  Madrid: {
    defaultMessage: 'Madrid',
  },
  Malaga: {
    defaultMessage: 'Malaga',
  },
  Mallorca: {
    defaultMessage: 'Mallorca',
  },
  Milan: {
    defaultMessage: 'Milan',
  },
  Paris: {
    defaultMessage: 'Paris',
  },
  Rome: {
    defaultMessage: 'Rome',
  },
  Valencia: {
    defaultMessage: 'Valencia',
  },
  Zurich: {
    defaultMessage: 'Zurich',
  },
  Turin: {
    defaultMessage: 'Turin',
  },
  Seville: {
    defaultMessage: 'Seville',
  },
  Amsterdam: {
    defaultMessage: 'Amsterdam',
  },
  Breda: {
    defaultMessage: 'Breda',
  },
  Den_bosch: {
    defaultMessage: 'Den Bosch',
  },
  The_hague: {
    defaultMessage: 'The Hague',
  },
  Groningen: {
    defaultMessage: 'Groningen',
  },
  Haarlem: {
    defaultMessage: 'Haarlem',
  },
  Nijmegen: {
    defaultMessage: 'Nijmegen',
  },
  Rotterdam: {
    defaultMessage: 'Rotterdam',
  },
  Tilburg: {
    defaultMessage: 'Tilburg',
  },
  Zwolle: {
    defaultMessage: 'Zwolle',
  },
  Enschede: {
    defaultMessage: 'Enschede',
  },
  Hilversum: {
    defaultMessage: 'Hilversum',
  },
  Brussels: {
    defaultMessage: 'Brussels',
  },
  Antwerp: {
    defaultMessage: 'Antwerp',
  },
});

export const countries = defineMessages({
  spain: {
    defaultMessage: 'Spain',
  },
  france: {
    defaultMessage: 'France',
  },
  portugal: {
    defaultMessage: 'Portugal',
  },
  italy: {
    defaultMessage: 'Italy',
  },
  germany: {
    defaultMessage: 'Germany',
  },
  switzerland: {
    defaultMessage: 'Switzerland',
  },
  netherlands: {
    defaultMessage: 'Netherlands',
  },
  belgium: {
    defaultMessage: 'Belgium',
  },
});

export const labels = defineMessages({
  system: {
    defaultMessage: 'System',
  },
});
