import { useSelectVehicle } from '~/libs/select-vehicle';
import {
  VehiclesMap,
  VehicleMapSource,
  batteryMapView,
  operationalMapView,
  VehicleFeatureImageProps,
  getFeaturesFromVehiclesList,
} from '~/common';
import { ExtendedVehicleListItem } from '~/libs/vehicles-filters';
import { MapView } from '~/hooks';

const featureImagePerMapView: { [key in MapView]: VehicleFeatureImageProps } = {
  'by-battery': new batteryMapView.BatteryLevelVehicleFeatureImage(),
  'by-status': new operationalMapView.OperationalVehicleFeatureImage(),
};

const mapSourcesPerMapView: { [key in MapView]: VehicleMapSource[] } = {
  'by-battery': batteryMapView.vehiclesMapSources,
  'by-status': operationalMapView.vehiclesMapSources,
};

const layersPerMapView: { [key in MapView]: string[] } = {
  'by-battery': batteryMapView.layers,
  'by-status': operationalMapView.layers,
};

export type VehiclesMultiViewMapProps = {
  vehicles: ExtendedVehicleListItem[];
  mapView: MapView;
};

export const VehiclesMultiViewMap = ({
  vehicles,
  mapView,
}: VehiclesMultiViewMapProps) => {
  const { vehicleId } = useSelectVehicle();

  return (
    <VehiclesMap
      interactiveLayerIds={layersPerMapView[mapView]}
      vehiclesMapSources={mapSourcesPerMapView[mapView]}
      vehicleFeatures={getFeaturesFromVehiclesList(
        vehicles,
        featureImagePerMapView[mapView],
        vehicleId
      )}
    />
  );
};
