import { System } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { ReactNode, useState } from 'react';

import { NoSystemPermission } from './NoSystemPermission/NoSystemPermission';
import { PreferredSystemContext } from './PreferredSystemContext';

export type PreferredSystemProviderProps = {
  children?: ReactNode;
  defaultSystem?: System;
  localStorageKey?: string;
};

export const PreferredSystemProvider = ({
  children,
  defaultSystem,
  localStorageKey = 'zeus-web-preferred-system',
}: PreferredSystemProviderProps) => {
  const { data: claims } = useAuthClaimsQuery();

  const getPreferredSystem = (): System | undefined => {
    const savedSystem = localStorage.getItem(localStorageKey) as System | null;
    if (!savedSystem) {
      return claims?.systems[0];
    }
    return savedSystem;
  };

  const [system, setSystem] = useState<System | undefined>(
    defaultSystem || getPreferredSystem()
  );

  const savePreferredSystem = (system: System) => {
    localStorage.setItem(localStorageKey, system);
  };

  const updateSystem = (system: System) => {
    savePreferredSystem(system);
    setSystem(system);
  };

  if (!system) {
    return <NoSystemPermission />;
  }

  return (
    <PreferredSystemContext.Provider value={{ system, updateSystem }}>
      {children}
    </PreferredSystemContext.Provider>
  );
};
