import { User } from '@cooltra/api';

export type DocumentsFormValues = {
  idCardNumber: string;
  taxIdNumber: string;
  taxIdCountry: string;
  driverLicenseExpirationDate: string;
  driverLicenseCategory: string;
  driverLicenseCountry: string;
  idCardFront: string;
  idCardBack: string;
  driverLicenseFront: string;
  driverLicenseBack: string;
  selfie: string;
};

export type DocumentsFormFieldName = keyof DocumentsFormValues;

export type DocumentsFormErrors = Partial<
  Record<DocumentsFormFieldName, string | undefined>
>;

export const documentsFormInitialValues = {
  idCardNumber: '',
  taxIdNumber: '',
  taxIdCountry: '',
  driverLicenseExpirationDate: '',
  driverLicenseCategory: '',
  driverLicenseCountry: '',
  idCardFront: '',
  idCardBack: '',
  driverLicenseFront: '',
  driverLicenseBack: '',
  selfie: '',
};

export const getDocumentsFormInitialValues = ({
  idCardNumber,
  taxIdNumber,
  taxIdCountry,
  driverLicenseExpirationDate,
  driverLicenseCategory,
  driverLicenseCountry,
  photos: {
    idCardFront,
    idCardBack,
    driverLicenseFront,
    driverLicenseBack,
    selfie,
  },
}: User): DocumentsFormValues => ({
  ...documentsFormInitialValues,
  idCardNumber,
  taxIdNumber,
  taxIdCountry,
  driverLicenseExpirationDate,
  driverLicenseCategory,
  driverLicenseCountry,
  idCardFront,
  idCardBack,
  driverLicenseFront,
  driverLicenseBack,
  selfie,
});

export const getUserDocumentsPayload = (
  {
    idCardBack,
    idCardFront,
    selfie,
    driverLicenseBack,
    driverLicenseFront,
    ...formValues
  }: DocumentsFormValues,
  version: number
) => ({
  ...formValues,
  photos: {
    idCardFront,
    idCardBack,
    driverLicenseFront,
    driverLicenseBack,
    selfie,
  },
  version,
});
