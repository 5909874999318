import { useGeofencesQuery } from '@cooltra/api';
import { useCallback, useEffect, useRef } from 'react';
import { LngLatBounds } from 'mapbox-gl';
import { MapRef, NavigationControl } from 'react-map-gl';

import { BaseMap, Geofences } from '~/common';
import { isTestingEnv } from '~/utils/e2e';

import { RouteWorkUnits } from './RouteWorkUnits';
import { RouteLine } from './RouteLine';
import { useShiftRouteCoordinates } from './shift-route-coordinates-provider';
export const ShiftMap = () => {
  const mapRef = useRef<MapRef | null>(null);
  const { routeCoordinates } = useShiftRouteCoordinates();
  const { data: geofences } = useGeofencesQuery();

  const fitMapBounds = useCallback(() => {
    if (routeCoordinates.length === 0) {
      return;
    }

    const bounds: LngLatBounds = new LngLatBounds(
      routeCoordinates[0],
      routeCoordinates[0]
    );

    routeCoordinates.forEach((coordinate) => {
      bounds.extend(coordinate);
    });

    mapRef.current?.fitBounds(bounds, {
      padding: 20,
      duration: 1000,
    });
  }, [routeCoordinates]);

  useEffect(() => {
    fitMapBounds();
  }, [fitMapBounds]);

  if (isTestingEnv()) {
    return <div className="h-full w-full bg-neutral-100" />;
  }

  return (
    <BaseMap
      id="shift-map"
      ref={mapRef}
      initialViewState={{ zoom: 13 }}
      style={{ width: '100%', height: '100%' }}
      onLoad={fitMapBounds}
      reuseMaps={false}
    >
      <NavigationControl showZoom showCompass={false} />
      <Geofences geofences={geofences} />
      <RouteLine />
      <RouteWorkUnits />
    </BaseMap>
  );
};
