import { Source, ViewStateChangeEvent } from 'react-map-gl';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { useGeofencesQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import { useMapCenter } from '~/libs/map-center';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { isTestingEnv } from '~/utils/e2e';

import { BonusZoneByHomeSystem } from '../BonusZone';
import { BaseMap, BaseMapProps } from '../BaseMap/BaseMap';
import { Geofences } from '../Geofences';

import { VehicleFeature, VehicleMapSource } from './types';

export type VehiclesMapProps = {
  vehiclesMapSources: VehicleMapSource[];
  vehicleFeatures: VehicleFeature[];
} & Pick<BaseMapProps, 'interactiveLayerIds'>;

export const VehiclesMap = ({
  interactiveLayerIds,
  vehiclesMapSources,
  vehicleFeatures,
}: VehiclesMapProps) => {
  const { selectVehicle } = useSelectVehicle();
  const { viewState, updateViewState } = useMapCenter();
  const { data: claims } = useAuthClaimsQuery();
  const { data: geofences } = useGeofencesQuery();

  const onLayerClick = (e: MapLayerMouseEvent) => {
    const features = e.features || [];
    features.forEach(({ geometry, properties }) => {
      const [longitude, latitude] = (geometry as any).coordinates;
      if (properties) {
        selectVehicle(properties.vehicleId);
      } else {
        updateViewState((prevState) => ({
          zoom: prevState.zoom < 16 ? 16 : prevState.zoom,
          latitude,
          longitude,
        }));
      }
    });
  };

  const onMove = ({
    viewState: { latitude, longitude, zoom },
  }: ViewStateChangeEvent) => updateViewState({ latitude, longitude, zoom });

  if (isTestingEnv()) {
    return <div className="h-full w-full bg-neutral-100" />;
  }

  return (
    <BaseMap
      interactiveLayerIds={interactiveLayerIds}
      style={{ width: '100%', height: '100%' }}
      onClick={onLayerClick}
      onMove={onMove}
      logoPosition="top-right"
      {...viewState}
    >
      <>
        <Geofences geofences={geofences} />
        {vehiclesMapSources.map((source) => (
          <Source
            key={source.id}
            id={source.id}
            type="geojson"
            data={{
              type: 'FeatureCollection',
              features: vehicleFeatures,
            }}
            filter={source.filter}
            cluster={source.cluster}
            clusterMaxZoom={14}
            clusterMinPoints={10}
            clusterRadius={40}
          >
            {source.layers}
          </Source>
        ))}
        {claims?.systems.map((system) => (
          <BonusZoneByHomeSystem key={system} system={system} withMarkers />
        ))}
      </>
    </BaseMap>
  );
};
