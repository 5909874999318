import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { DocumentsFormValues } from './documents-form';
import messages from './messages';

export type RestoreIdCardProps = {
  onRestore: () => void;
};

export const RestoreIdCard = ({ onRestore }: RestoreIdCardProps) => {
  const { setValues, initialValues } = useFormContext<DocumentsFormValues>();

  const restoreIdCard = () => {
    onRestore();
    setValues({
      idCardFront: initialValues.idCardFront,
      idCardBack: initialValues.idCardBack,
      idCardNumber: initialValues.idCardNumber,
      taxIdNumber: initialValues.taxIdNumber,
      taxIdCountry: initialValues.taxIdCountry,
    });
  };

  return (
    <Button
      data-testid="ID_CARD_RESTORE_BUTTON"
      type="button"
      size="sm"
      onClick={restoreIdCard}
    >
      <FormattedMessage {...messages.restoreIdCardBtn} />
    </Button>
  );
};
