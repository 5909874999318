import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { MdError } from 'react-icons/md';

import { useContractFlags } from '~/hooks';

import { ContractPaymentsButtons } from '../ContractPaymentsButtons/ContractPaymentsButtons';
import * as ContractPaymentsItems from '../ContractPaymentsItems';

import messages from './messages';

export type ContractPendingPaymentsProps = {
  contract: Contract;
  contratPaymentTypes: ContractPayment[];
};

export const ContractPendingPayments = ({
  contract,
  contratPaymentTypes,
}: ContractPendingPaymentsProps) => {
  const { isSignedOrPendingSignature } = useContractFlags();

  if (contract.status === 'NO_SHOW') {
    return (
      <Card data-testid="PENDING_PAYMENTS_CARD" className="py-8 px-14 mb-4">
        <h2 className="text-lg text-neutral-800">
          <div className="flex justify-between gap-8">
            <FormattedMessage {...messages.pending} />
            <span>-</span>
          </div>
        </h2>
      </Card>
    );
  }

  const pendingPayments = contratPaymentTypes.find(
    (payment) => payment.status === 'PENDING'
  );

  const getPendingPaymentsTitle = () => {
    if (pendingPayments) {
      return (
        <h2 className="text-lg text-neutral-800 mb-8">
          <div className="flex items-center justify-left gap-2">
            <FormattedMessage {...messages.pending} />
            {contract.pendingAmount.value > 0 && (
              <MdError
                className="text-warning-500 text-xl"
                data-testid="PENDING_PAYMENTS_WARNING"
              />
            )}
          </div>
        </h2>
      );
    }

    return (
      <h2 className="text-lg text-neutral-800">
        <div className="flex justify-between gap-8">
          <FormattedMessage {...messages.pending} />
          <span>-</span>
        </div>
      </h2>
    );
  };

  return (
    <Card data-testid="PENDING_PAYMENTS_CARD" className="py-8 px-14 mb-4">
      {getPendingPaymentsTitle()}
      {pendingPayments && (
        <div>
          <ContractPaymentsItems.Vehicle
            className="mb-2"
            payment={pendingPayments}
          />
          <ContractPaymentsItems.Coverage
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={pendingPayments}
          />
          <ContractPaymentsItems.Accessories
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={pendingPayments}
          />
          <ContractPaymentsItems.Penalties
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={pendingPayments}
          />
          <ContractPaymentsItems.ValueAdjustment
            contractId={contract.contractId}
            className="mb-2 mt-6 pt-6 border-t border-neutral-100"
            payment={pendingPayments}
          />
          <ContractPaymentsItems.Total
            className="mb-10 mt-6 pt-6 border-t border-neutral-100"
            payment={pendingPayments}
          />
          <ContractPaymentsButtons
            hasPendingPayments={!!pendingPayments}
            disabled={isSignedOrPendingSignature}
            contract={contract}
          />
        </div>
      )}
      {!pendingPayments && (
        <ContractPaymentsButtons
          hasPendingPayments={!!pendingPayments}
          contract={contract}
          showChargeButton={false}
          disabled={isSignedOrPendingSignature}
        />
      )}
    </Card>
  );
};
