import { ButtonHTMLAttributes } from 'react';
import { Button, Icon } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdRefresh } from 'react-icons/md';

import messages from './messages';

type RefreshButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading: boolean;
};

export const RefreshButton = ({ isLoading, ...rest }: RefreshButtonProps) => (
  <div className="bg-neutral-0 shadow rounded-md">
    <Button
      className="h-11 pl-3 pr-5 text-neutral-500 text-base font-semibold bg-neutral-0 rounded-md"
      loading={isLoading}
      {...rest}
    >
      <Icon className="text-sm mr-1">
        <MdRefresh />
      </Icon>
      <FormattedMessage {...messages.refresh} />
    </Button>
  </div>
);
