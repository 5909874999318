import { Form, FormButton, FormProvider } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Provider,
  System,
  useChargeUserMutation,
  useUserInvoicesQuery,
} from '@cooltra/api';

import { useNotification, usePolling, usePrevious } from '~/hooks';

import * as ChargeUserFields from '../ChargeUserFields';

import {
  ChargeUserFormValues,
  createChargeUserInitialFormValues,
  validateChargeUserForm,
} from './charge-user-form';
import messages from './messages';
import { ChargeUserModal } from './ChargeUserModal';

export type ChargeUserProps = {
  userId: string;
  system: System;
  provider: Provider;
};

export const ChargeUser = ({ userId, system, provider }: ChargeUserProps) => {
  const intl = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { data: invoices, refetch } = useUserInvoicesQuery(userId);
  const previousInvoicesTotal = usePrevious(invoices?.total);

  const { addErrorNotification } = useNotification();

  const { mutateAsync, isSuccess, reset } = useChargeUserMutation(userId);

  const onSubmit = async (values: ChargeUserFormValues) => {
    return mutateAsync(values).catch(() => addErrorNotification());
  };

  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: () => {
      reset();
      toggleOff();
    },
    interval: isSuccess && previousInvoicesTotal === invoices?.total ? 2000 : 0,
  });

  return (
    <FormProvider
      disabled={isPolling}
      validate={validateChargeUserForm(intl)}
      initialValues={createChargeUserInitialFormValues(system)}
      onSubmit={onSubmit}
    >
      <Button emphasis="high" size="md" onClick={toggleOn}>
        <FormattedMessage {...messages.invoiceCharge} />
      </Button>
      <ChargeUserModal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        system={system}
      >
        <div data-testid="CHARGE_USER_MODAL" className="pb-12 px-12">
          <h6 className="text-neutral-400 text-lg mb-6">
            <FormattedMessage {...messages.invoiceCharge} />
          </h6>
          <Form className="grid grid-cols-2 gap-x-4 gap-y-6">
            <div className="col-span-2">
              <ChargeUserFields.Category provider={provider} />
            </div>
            <div className="col-span-2">
              <ChargeUserFields.LegalEntity provider={provider} />
            </div>
            <div className="col-span-2">
              <ChargeUserFields.Item />
            </div>
            <ChargeUserFields.Amount />
            <div className="mb-4">
              <ChargeUserFields.TaxRate />
            </div>
            <Button type="button" onClick={toggleOff}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <FormButton disableInvalid loading={isPolling} emphasis="high">
              <FormattedMessage {...messages.save} />
            </FormButton>
          </Form>
        </div>
      </ChargeUserModal>
    </FormProvider>
  );
};
