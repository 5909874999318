import { FormattedMessage, useIntl } from 'react-intl';
import { Card } from '@cooltra/ui';
import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import {
  Contract,
  usePatchContractChecksInMutation,
} from '@cooltra/station-based-api';
import { RouterLink } from '@cooltra/navigation';
import { MdOpenInNew } from 'react-icons/md';

import { useNotification } from '~/hooks';
import { FormQRCode, FormSaveButton, UnsavedChangesPrompt } from '~/common';

import * as ChecksFields from '../../../ChecksFields';

import {
  ChecksFormValues,
  initialValues,
  validateChecksInForm,
} from './checks-form';
import messages from './messages';

export type ChecksInProps = {
  contract: Contract;
  disabled: boolean;
};

export const ChecksIn = ({ contract, disabled }: ChecksInProps) => {
  const intl = useIntl();
  const { checks, accessories, contractId, vehicle } = contract;
  const { licensePlate, vehicleId } = vehicle?.assignedVehicle || {};

  const { mutateAsync, isSuccess } =
    usePatchContractChecksInMutation(contractId);
  const { addErrorNotification } = useNotification();

  const getInitialFormValues = () => {
    if (checks) {
      const currentSelectedDeliveredAccessories: Record<string, boolean> = {};

      checks.accessories?.forEach((accessory, index) => {
        currentSelectedDeliveredAccessories[String(index)] =
          accessory.delivered;
      });

      return {
        ...initialValues,
        mileage: Number(checks.in?.mileage) || '',
        fuelLevel: checks.in?.fuelLevel || 0,
        accessories: currentSelectedDeliveredAccessories,
      };
    }

    return initialValues;
  };

  const handleOnSubmit = async (
    values: ChecksFormValues,
    { resetForm }: FormHelpers<ChecksFormValues>
  ) => {
    const selectedIndexes = Object.keys(values.accessories).filter(
      (index) => values.accessories[index]
    );
    const selectedDeliveredAccessories = selectedIndexes.map(
      (index) => checks?.accessories[Number(index)].accessoryId
    );

    return await mutateAsync({
      fuelLevel: values.fuelLevel,
      mileage: Number(values.mileage),
      deliveredAccessories: selectedDeliveredAccessories,
    })
      .then(() => resetForm(values))
      .catch(() => addErrorNotification());
  };

  return (
    <FormProvider
      onSubmit={handleOnSubmit}
      initialValues={getInitialFormValues()}
      validate={validateChecksInForm(intl, contract)}
    >
      <UnsavedChangesPrompt />
      <Form>
        <div className="mx-auto max-w-4xl pt-4 pb-10">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-xl text-neutral-700 mb-6">
              <FormattedMessage {...messages.checksIn} />
            </h1>
            <RouterLink
              className="inline-flex gap-1 items-center text-s font-semibold"
              to={`/pos/vehicles/${vehicleId}`}
            >
              <FormattedMessage
                {...messages.openDetails}
                values={{
                  licensePlate,
                }}
              />
              <MdOpenInNew />
            </RouterLink>
          </div>
          <Card className="p-8">
            <div className="grid grid-cols-2 gap-8 mb-6">
              <ChecksFields.Mileage disabled={disabled} />
              {vehicle?.category !== 'EBIKE' && (
                <ChecksFields.LevelSelector
                  levels={8}
                  name="fuelLevel"
                  disabled={disabled}
                />
              )}
            </div>
            {accessories && (
              <ChecksFields.Accessories
                accessories={checks.accessories}
                disabled={disabled}
              />
            )}
          </Card>
        </div>
        <FormSaveButton
          className="btn-shadow fixed bottom-8 right-28 -mr-2 z-10"
          isSaved={isSuccess}
          disabled={disabled}
        />
      </Form>
      <FormQRCode />
    </FormProvider>
  );
};
