import { useGeofencesQuery, Rental, System } from '@cooltra/api';
import { NavigationControl } from 'react-map-gl';

import { BaseMap, DropOffMarker, Geofences } from '~/common';
import { isTestingEnv } from '~/utils/e2e';

export type RentalDropOffMapProps = Pick<Rental, 'dropOff'> & {
  system: System;
};

export const RentalDropOffMap = ({ dropOff }: RentalDropOffMapProps) => {
  const { data: geofences } = useGeofencesQuery();

  if (isTestingEnv() || !dropOff) {
    return <div className="h-full w-full bg-neutral-100" />;
  }

  return (
    <BaseMap
      id="rental-drop-off-map"
      initialViewState={{
        zoom: 16,
        latitude: dropOff.latitude,
        longitude: dropOff.longitude,
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <NavigationControl showZoom showCompass={false} />
      <Geofences geofences={geofences} />
      {dropOff && (
        <DropOffMarker
          latitude={dropOff.latitude}
          longitude={dropOff.longitude}
        />
      )}
    </BaseMap>
  );
};
