import { FormattedMessage } from 'react-intl';
import { Provider, System } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { RouterTab } from '@cooltra/navigation';
import { Route, Routes } from 'react-router-dom';

import { Invoices, ChargeUser } from '~/common';

import { Discounts } from '../Discounts';
import { AddCredit, Credits } from '../Credits';
import { Subscriptions } from '../Subscriptions';
import { NewSubscription } from '../Subscriptions/NewSubscription/NewSubscription';
import { InvoiceAll, UninvoicedCharges } from '../UninvoicedCharges';
import { RentalPasses } from '../RentalPasses/RentalPasses/RentalPasses';

import messages from './messages';

export type BillingProps = {
  userId: string;
  system: System;
  provider: Provider;
};

export const Billing = ({ userId, system, provider }: BillingProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  const canViewCredits = hasPermission('read:credits');
  const canWriteCredits = hasPermission('write:credits');
  const canWriteSubscriptions = hasPermission('write:subscriptions');
  const canViewInvoices = hasPermission('read:invoices');
  const canWriteInvoices = hasPermission('write:invoices');
  const canViewOffers = hasPermission('read:offers');

  return (
    <div
      className="container min-w-3xl max-w-6xl py-12"
      data-testid="USER_BILLING"
    >
      <div className="flex gap-8">
        <div className="flex-1 flex gap-6">
          {canViewCredits && (
            <RouterTab className="py-2 truncate" to="credits">
              <FormattedMessage {...messages.credits} />
            </RouterTab>
          )}
          {canViewInvoices && (
            <RouterTab className="py-2 truncate" to="uninvoiced-charges">
              <FormattedMessage {...messages.uninvoicedCharges} />
            </RouterTab>
          )}
          {canViewInvoices && (
            <RouterTab className="py-2 truncate" to="invoices">
              <FormattedMessage {...messages.invoices} />
            </RouterTab>
          )}
          {canViewOffers && (
            <RouterTab className="py-2 truncate" to="discounts">
              <FormattedMessage {...messages.discounts} />
            </RouterTab>
          )}
          {canViewOffers && (
            <RouterTab className="py-2 truncate" to="rental-passes">
              <FormattedMessage {...messages.rentalPasses} />
            </RouterTab>
          )}
          <RouterTab className="py-2 truncate" to="subscriptions">
            <FormattedMessage {...messages.subscriptions} />
          </RouterTab>
        </div>
        <div>
          <Routes>
            {canWriteCredits && (
              <Route path="credits" element={<AddCredit userId={userId} />} />
            )}
            {canWriteInvoices && (
              <Route
                path="invoices"
                element={
                  <ChargeUser
                    userId={userId}
                    system={system}
                    provider={provider}
                  />
                }
              />
            )}
            {canWriteInvoices && (
              <Route
                path="uninvoiced-charges"
                element={<InvoiceAll userId={userId} />}
              />
            )}
            {canWriteSubscriptions && (
              <Route
                path="subscriptions"
                element={<NewSubscription userId={userId} />}
              />
            )}
          </Routes>
        </div>
      </div>
      <Routes>
        {canViewInvoices && (
          <Route path="invoices" element={<Invoices userId={userId} />} />
        )}
        {canViewInvoices && (
          <Route
            path="uninvoiced-charges"
            element={<UninvoicedCharges userId={userId} />}
          />
        )}
        {canViewCredits && (
          <Route path="credits" element={<Credits userId={userId} />} />
        )}
        {canViewOffers && (
          <Route path="discounts" element={<Discounts userId={userId} />} />
        )}
        {canViewOffers && (
          <Route
            path="rental-passes"
            element={<RentalPasses userId={userId} />}
          />
        )}
        <Route
          path="subscriptions"
          element={<Subscriptions userId={userId} />}
        />
      </Routes>
    </div>
  );
};
