import { IntlShape } from 'react-intl';

import {
  validateRequired,
  validateDate,
  validateExpirationDate,
  validateTaxId,
  createSetError,
  validateId,
} from '~/validation';

import {
  DocumentsFormErrors,
  DocumentsFormFieldName,
  DocumentsFormValues,
} from './documents-form';

const requiredDriverLicenseFields: DocumentsFormFieldName[] = [
  'driverLicenseFront',
  'driverLicenseExpirationDate',
  'driverLicenseCategory',
  'driverLicenseCountry',
];

const requiredIdCardFields: DocumentsFormFieldName[] = [
  'idCardNumber',
  'taxIdNumber',
  'taxIdCountry',
  'idCardFront',
];

export const validateDocuments =
  (
    intl: IntlShape,
    isIdCardSectionVisible: boolean,
    isDLSectionVisible: boolean
  ) =>
  (values: DocumentsFormValues): DocumentsFormErrors => {
    const errors: DocumentsFormErrors = {};
    const setError = createSetError(errors);

    const {
      idCardNumber,
      taxIdCountry,
      taxIdNumber,
      idCardFront,
      idCardBack,
      driverLicenseFront,
      driverLicenseBack,
      driverLicenseCategory,
      driverLicenseCountry,
      driverLicenseExpirationDate,
    } = values;

    function checkIdCardNumber() {
      if (values.taxIdCountry) {
        setError(
          'idCardNumber',
          validateId(intl, values.idCardNumber, values.taxIdCountry)
        );
        setError(
          'taxIdNumber',
          validateTaxId(intl, values.taxIdNumber, values.taxIdCountry)
        );
      }
    }

    if (isIdCardSectionVisible) {
      checkIdCardNumber();
      requiredIdCardFields.forEach((fieldName) => {
        setError(fieldName, validateRequired(intl, values[fieldName]));
      });
    } else {
      const hasAnyIdCardValue =
        !!idCardNumber ||
        !!taxIdCountry ||
        !!taxIdNumber ||
        !!idCardFront ||
        !!idCardBack;

      if (hasAnyIdCardValue) {
        checkIdCardNumber();
        requiredIdCardFields.forEach((fieldName) => {
          setError(fieldName, validateRequired(intl, values[fieldName]));
        });
      }
    }

    function checkExpirationDate() {
      setError(
        'driverLicenseExpirationDate',
        validateExpirationDate(intl, values.driverLicenseExpirationDate)
      );

      setError(
        'driverLicenseExpirationDate',
        validateDate(intl, values.driverLicenseExpirationDate)
      );
    }

    if (isDLSectionVisible) {
      checkExpirationDate();
      requiredDriverLicenseFields.forEach((fieldName) => {
        setError(fieldName, validateRequired(intl, values[fieldName]));
      });
    } else {
      const hasAnyDriverLicenseValue =
        !!driverLicenseFront ||
        !!driverLicenseBack ||
        !!driverLicenseCategory ||
        !!driverLicenseCountry ||
        !!driverLicenseExpirationDate;

      if (hasAnyDriverLicenseValue) {
        checkExpirationDate();
        requiredDriverLicenseFields.forEach((fieldName) => {
          setError(fieldName, validateRequired(intl, values[fieldName]));
        });
      }
    }
    return errors;
  };
