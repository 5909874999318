import { FormattedMessage, useIntl } from 'react-intl';
import { allHomeSystemsList, System } from '@cooltra/api';
import { useField, useFormContext } from '@cooltra/form';
import { InputError, InputLabel, MultiSelectField } from '@cooltra/ui';

import { PromotionFormValues } from '../../PromotionForm';
import { PromotionStateType } from '../../PromotionState/PromotionState';
import { useAllSystemOptions } from '../../../../System';

import messages from './messages';

export const TargetHomeSystems = () => {
  const { formatMessage } = useIntl();
  const systemOptions = useAllSystemOptions();
  const { values, isSubmitting, initialValues } =
    useFormContext<PromotionFormValues>();
  const { value, error, touched, setValue, setTouched } =
    useField('targetHomeSystems');
  const isSystemIncludedOnInitialValues = (system: System) =>
    initialValues.targetHomeSystems.includes(system);
  const isPromotionSavedWithAllHomeSystems =
    initialValues.targetHomeSystems.length === allHomeSystemsList.length;
  const editingExpiredPromotion =
    values.editMode && values.stateType === PromotionStateType.EXPIRED;

  return (
    <MultiSelectField
      id="targetHomeSystems"
      name={'targetHomeSystems'}
      options={systemOptions}
      value={isPromotionSavedWithAllHomeSystems ? [] : value}
      disabled={
        isSubmitting ||
        isPromotionSavedWithAllHomeSystems ||
        editingExpiredPromotion
      }
      placeholder={formatMessage(messages.allHomeSystems)}
      onChange={(selectedOptions, actionMeta) => {
        let values: string[] = [];

        switch (actionMeta.action) {
          case 'remove-value':
          case 'pop-value':
            if (
              isSystemIncludedOnInitialValues(
                actionMeta.removedValue.value as System
              )
            ) {
              return;
            }

            values = selectedOptions.map(({ value }) => value);
            break;
          case 'clear':
            values = initialValues.targetHomeSystems;
            break;
          default:
            values = selectedOptions.map(({ value }) => value);
            break;
        }

        setValue(values);
      }}
      onBlur={() => setTouched(true)}
      isClearable={value.some(
        (system: System) => !isSystemIncludedOnInitialValues(system)
      )}
      label={
        <InputLabel htmlFor="targetHomeSystems">
          <FormattedMessage {...messages.homeSystem} />
        </InputLabel>
      }
      error={touched && error ? <InputError>{error}</InputError> : undefined}
    />
  );
};
