import {
  Model,
  System,
  Telematics,
  VehiclesSort,
  OperationalCondition,
  ThreeStateValue,
  ParkingReview,
} from '@cooltra/api';

type OperationalConditionPrefix = '' | '!';

export type ExtendedOperationalCondition =
  `${OperationalConditionPrefix}${OperationalCondition}`;

export type IdleFrom =
  | '1-20h'
  | '20-24h'
  | '1-2d'
  | '2-3d'
  | '3-4d'
  | '4-5d'
  | '5-10d'
  | '+10d';

export type VehiclesFiltersFormValues = {
  system: System[];
  model: Model[];
  telematics: Telematics[];
  location: string[];
  sort: VehiclesSort;
  cityHallPublished: ThreeStateValue;
  keyword: string;
  minBatteryCharge: number;
  maxBatteryCharge: number;
  taskOperatorGroupId: string[];
  taskOperatorId: string[];
  tagId: string[];
  operationalCondition: ExtendedOperationalCondition[];
  withOperator: string;
  bonusEnabled: ThreeStateValue;
  parkingReview: ParkingReview[];
  idleFrom: IdleFrom[];
  unbalanced: ThreeStateValue;
};

export const vehiclesFiltersInitialValues: VehiclesFiltersFormValues = {
  system: [],
  model: [],
  telematics: [],
  location: [],
  sort: 'externalId.asc',
  cityHallPublished: '',
  minBatteryCharge: 0,
  maxBatteryCharge: 100,
  keyword: '',
  taskOperatorGroupId: [],
  taskOperatorId: [],
  tagId: [],
  operationalCondition: [],
  withOperator: '',
  bonusEnabled: '',
  parkingReview: [],
  idleFrom: [],
  unbalanced: '',
};
