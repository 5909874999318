import { defineMessages } from 'react-intl';

export default defineMessages({
  idCard: {
    defaultMessage: 'Identity document',
  },
  driverLicense: {
    defaultMessage: 'Driver license',
  },
  selfie: {
    defaultMessage: 'Selfie',
  },
  submitBtn: {
    defaultMessage: 'Save changes',
  },
  duplicated: {
    defaultMessage: 'It has already been taken',
  },
  submissionSuccess: {
    defaultMessage: "You have successfully updated user's documents!",
  },
  conflictErrorMessage: {
    defaultMessage:
      'The user details have been updated in the meantime. Please refresh the page.',
  },
  removeDriverLicenseBtn: {
    defaultMessage: 'Remove',
  },
  restoreDriverLicenseBtn: {
    defaultMessage: 'Restore',
  },
  addDriverLicense: {
    defaultMessage: 'Add',
  },
  removeIdCardBtn: {
    defaultMessage: 'Remove',
  },
  restoreIdCardBtn: {
    defaultMessage: 'Restore',
  },
  addIdCard: {
    defaultMessage: 'Add',
  },
});
