import { System } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import { StaticField } from '../../../StaticField/StaticField';
import { SystemCity } from '../../../System';

import messages from './messages';

export type SystemStaticProps = {
  value: System;
};

export const SystemStatic = ({ value }: SystemStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.system} />}
    renderValue={<SystemCity system={value} />}
  />
);
