import { System } from '@cooltra/api';
import { useToggle } from '@cooltra/hooks';
import { MdOutlineExpandMore } from 'react-icons/md';
import { Icon } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { useParams } from 'react-router-dom';

import { SystemCity, useSystemOptions } from '~/common';

import { HomeSystemLink } from './HomeSystemLink';

export const HomeSystemSelector = () => {
  const [isOpen, { toggle }] = useToggle();

  const params = useParams<'system'>();
  const system = params.system as System;

  const systems = useSystemOptions();

  const canAccessOneSystem = systems.length === 1;

  return (
    <div className="relative z-40 h-20">
      <div
        className="absolute w-full text-center shadow-md bg-neutral-0"
        data-testid="SELECTOR_HOME_SYSTEM"
      >
        {canAccessOneSystem ? (
          <div className="h-20">
            <span className="flex h-full justify-center items-center">
              <span className="block text-lg font-semibold text-neutral-600">
                <SystemCity system={system} />
              </span>
            </span>
          </div>
        ) : (
          <>
            <button
              onClick={toggle}
              className="h-20 w-full focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-200 transition-all"
            >
              <span className="flex w-full justify-center">
                <span className="block pr-2 text-lg font-semibold text-neutral-600">
                  <SystemCity system={system} />
                </span>
                <Icon
                  className={classNames(
                    'text-xl relative transition-transform text-neutral-800',
                    isOpen ? 'rotate-180' : 'rotate-0'
                  )}
                >
                  <MdOutlineExpandMore />
                </Icon>
              </span>
            </button>
            {isOpen && (
              <div className="bg-neutral-0 mx-auto max-w-4xl pb-16">
                <ul className="grid grid-cols-6 gap-4">
                  {systems.map(({ label, value }) => (
                    <HomeSystemLink system={value} onClick={toggle} key={value}>
                      {label}
                    </HomeSystemLink>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
