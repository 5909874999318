import { FormattedMessage } from 'react-intl';
import { List } from '@cooltra/ui';
import { BonusZone, System, useBonusZonesQuery } from '@cooltra/api';

import { ListTotal } from '~/common';

import { BonusZoneCard } from '../BonusZoneCard/BonusZoneCard';
import { NewBonusZoneLink } from '../NewBonusZoneLink/NewBonusZoneLink';

import messages from './messages';

export type BonusZonesProps = {
  system: System;
};

export const BonusZones = ({ system }: BonusZonesProps) => {
  const { isLoading, data, isError } = useBonusZonesQuery(system);

  return (
    <div className="py-4">
      <div className="flex items-center justify-between mb-6">
        {isError ? (
          <span />
        ) : (
          <>
            <ListTotal loading={isLoading}>
              <FormattedMessage
                {...messages.bonusZones}
                values={{
                  total: data?.bonusZones.length || 0,
                }}
              />
            </ListTotal>
            <NewBonusZoneLink />
          </>
        )}
      </div>
      <div>
        {isError ? (
          <span className="text-neutral-600 text-sm block text-center pt-10">
            <FormattedMessage {...messages.error} />
          </span>
        ) : (
          <List
            isLoading={isLoading}
            idProp="bonusZoneId"
            data={data?.bonusZones || []}
            loadingRows={4}
            renderEmptyMessage={null}
            renderHeader={null}
            renderLoadingRow={null}
            renderRow={(bonusZone: BonusZone) => (
              <BonusZoneCard {...bonusZone} system={system} />
            )}
          />
        )}
      </div>
    </div>
  );
};
