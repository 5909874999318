import {
  allHomeSystemsList,
  allVehicleModelsList,
  CreatePromotionPayload,
  Model,
  System,
} from '@cooltra/api';

import {
  formatCodeValues,
  formatDiscountAndCreditValues,
  formatPromotionDates,
  formatPromotionTranslations,
  PromotionFormValues,
} from '~/common';

export const getCreatePromotionPayload = (
  values: PromotionFormValues
): Omit<CreatePromotionPayload, 'operatorGroupIds'> => {
  const dates = formatPromotionDates(values);
  const codeValues = formatCodeValues(values);
  const discountAndCreditValues = formatDiscountAndCreditValues(values);
  const translations = formatPromotionTranslations(values);

  return {
    about: values.about,
    maxUses: values.maxUses ? Number(values.maxUses) : null,
    registration: values.registration,
    userCanRedeemMoreThanOneCode: values.userCanRedeemMoreThanOneCode,
    targetHomeSystems:
      values.targetHomeSystems.length === 0
        ? (allHomeSystemsList as System[])
        : values.targetHomeSystems,
    targetVehicleModels:
      values.targetVehicleModels.length === 0
        ? (allVehicleModelsList as Model[])
        : values.targetVehicleModels,
    ...discountAndCreditValues,
    ...dates,
    ...codeValues,
    ...translations,
  };
};
