import { TopLoadingBar } from '@cooltra/ui';

import { useFilteredVehiclesQuery } from '~/libs/vehicles-filters';
import { ErrorPage, VehicleFiltersModal } from '~/common';
import { SelectVehicleProvider } from '~/libs/select-vehicle';
import { usePreferredMapView } from '~/hooks';

import { PreviewVehicleCard } from './PreviewVehicle/PreviewVehicleCard';
import { OpsMapSwitchView } from './OpsMapSwitchView/OpsMapSwitchView';
import { GoToListViewButton } from './GoToListViewButton/GoToListViewButton';
import { VehiclesMultiViewMap } from './VehiclesMultiViewMap/VehiclesMultiViewMap';
import { OperationalFleetLegend } from './OperationalFleetLegend/OperationalFleetLegend';
import { AddressSearch } from './AddressSearch/AddressSearch';
import { RefreshButton } from './RefreshButton/RefreshButton';

export const OpsMapView = () => {
  const [mapView, updateMapView] = usePreferredMapView();
  const {
    data: { vehicles },
    refetch,
    isError,
    isLoading,
    isRefetching,
  } = useFilteredVehiclesQuery();

  if (isError) {
    return <ErrorPage />;
  }

  const refreshMap = () => refetch();

  return (
    <SelectVehicleProvider>
      {isLoading && <TopLoadingBar />}
      <div
        className="w-full absolute left-0 top-0 h-full pt-14"
        data-testid="OPS_MAP"
      >
        <PreviewVehicleCard />
        <div className="flex gap-4 absolute left-6 top-20 z-10">
          <GoToListViewButton />
          <VehicleFiltersModal disabled={isLoading} />
          <RefreshButton
            isLoading={isLoading || isRefetching}
            onClick={refreshMap}
          />
        </div>
        <VehiclesMultiViewMap mapView={mapView} vehicles={vehicles} />
        <OpsMapSwitchView mapView={mapView} updateMapView={updateMapView} />
        <OperationalFleetLegend vehicles={vehicles} isLoading={isLoading} />
        <AddressSearch />
      </div>
    </SelectVehicleProvider>
  );
};
