import { FormattedMessage, useIntl } from 'react-intl';
import {
  InputLabel,
  MultiSelectField,
  SelectField,
  TopLoadingBar,
} from '@cooltra/ui';
import { System } from '@cooltra/api';
import { useServicePointsQuery } from '@cooltra/station-based-api';

import { useSystemOptions } from '~/common';
import { usePreferredSystem } from '~/libs/preferred-system';
import { usePreferredServicePoints } from '~/libs/station-based/preferred-station-based-service-points';

import { SectionLayout } from '../SectionLayout/SectionLayout';

import messages from './messages';

type ServicePointIdOption = {
  label: string;
  value: string;
};

export const Preferences = () => {
  const intl = useIntl();
  const { system, updateSystem } = usePreferredSystem();
  const { preferredServicePoints, updateServicePoints } =
    usePreferredServicePoints();

  const { data: servicePoints, isLoading } = useServicePointsQuery();
  const systemOptions = useSystemOptions();

  if (isLoading) {
    <TopLoadingBar />;
  }

  const servicePointsOptions: ServicePointIdOption[] = servicePoints
    ? servicePoints
        .map((servicePoint) => ({
          label: servicePoint.name,
          value: servicePoint.id,
        }))
        .sort((servicePoint, nextServicePoint) =>
          servicePoint.label.localeCompare(nextServicePoint.label)
        )
    : [];

  return (
    <>
      <SectionLayout
        renderHeader={
          <div className="flex items-center">
            <h4 className="text-xl font-semibold text-neutral-500">
              <FormattedMessage {...messages.heading} />
            </h4>
          </div>
        }
        renderLeftContent={
          <div>
            {systemOptions.length > 1 && (
              <SelectField
                className="mb-6"
                value={system}
                disabled={!systemOptions.length}
                id="home-system"
                onChange={(system) => updateSystem(system as System)}
                options={systemOptions}
                label={
                  <InputLabel htmlFor="home-system">
                    <FormattedMessage {...messages.homeSystemLabel} />
                  </InputLabel>
                }
              />
            )}
            <MultiSelectField
              id="servicePointId"
              name="servicePointId"
              className="mb-6"
              value={preferredServicePoints}
              label={
                <InputLabel htmlFor="servicePointId" className="shrink-0">
                  <FormattedMessage {...messages.preferredServicePoints} />
                </InputLabel>
              }
              options={servicePointsOptions}
              placeholder={intl.formatMessage(messages.all)}
              onChange={(selectedOptions) => {
                updateServicePoints(
                  selectedOptions.map((servicePoint) => servicePoint.value)
                );
              }}
            />
          </div>
        }
      />
    </>
  );
};
