import { System } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import * as VehicleBulkActionButtons from '../VehicleBulkActionButtons';
import { DownloadCsv } from '../DownloadCsv/DownloadCsv';

export type FloatingMenuProps = {
  systems: System[];
  refetchVehicles: () => void;
  selectedVehiclesIds: string[];
  noVehiclesFound: boolean;
  canVehicleBeTransferred: boolean;
  areMyTakenVehiclesSelected: boolean;
};

export const FloatingMenu = ({
  systems,
  refetchVehicles,
  selectedVehiclesIds,
  noVehiclesFound,
  canVehicleBeTransferred,
  areMyTakenVehiclesSelected,
}: FloatingMenuProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  return (
    <div className="flex justify-between">
      {hasPermission('export:vehicles') ? (
        <div className="mt-4">
          <DownloadCsv disabled={noVehiclesFound} />
        </div>
      ) : (
        <span />
      )}
      {hasPermission('bulk:actions') && (
        <div className="flex gap-4">
          <div className="mt-4">
            <VehicleBulkActionButtons.MoveToHomeSystem
              refetchVehicles={refetchVehicles}
              disabled={!canVehicleBeTransferred}
              vehicleIds={selectedVehiclesIds}
            />
          </div>
          <div className="mt-4">
            <VehicleBulkActionButtons.CreateTechnicalInspection
              refetchVehicles={refetchVehicles}
              vehicleIds={selectedVehiclesIds}
            />
          </div>
          {hasPermission('bulk:tasks') && (
            <div className="mt-4">
              <VehicleBulkActionButtons.AddVehiclesTask
                vehicleIds={selectedVehiclesIds}
              />
            </div>
          )}
          {hasPermission('transfer:vehicles') && (
            <>
              <div className="mt-4">
                <VehicleBulkActionButtons.Transfer
                  refetchVehicles={refetchVehicles}
                  disabled={!canVehicleBeTransferred}
                  ignoreTakenVehicles={!areMyTakenVehiclesSelected}
                  systems={systems}
                  vehicleIds={selectedVehiclesIds}
                />
              </div>
              <div className="mt-4">
                <VehicleBulkActionButtons.ReleaseFromLocations
                  refetchVehicles={refetchVehicles}
                  vehicleIds={selectedVehiclesIds}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
