import { useToggle } from '@cooltra/hooks';
import { Button, Modal } from '@cooltra/ui';
import { MdQrCode } from 'react-icons/md';
import { useIntl } from 'react-intl';
import ReactQrCode from 'react-qr-code';
import { useLocation, useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';

import messages from './messages';

export type QRCode = {
  onOpen?: (isDesktop: boolean) => void;
  onClose?: (isDesktop: boolean) => void;
};

export const QRCode = ({ onOpen, onClose }: QRCode) => {
  const navigate = useNavigate();

  const { pathname, search } = useLocation();

  const { formatMessage } = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  const isIpad =
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform);

  const isDesktop = !isTablet && !isIpad;

  const handleOpen = () => {
    onOpen && onOpen(isDesktop);
    toggleOn();
  };

  const handleClose = () => {
    onClose && onClose(isDesktop);
    toggleOff();
  };

  return (
    <>
      <Button
        className="fixed bottom-8 right-8 rounded-full"
        square
        size="lg"
        variant="neutral"
        emphasis="high"
        onClick={handleOpen}
        aria-label={formatMessage(messages.qrCode)}
      >
        <MdQrCode className="text-3xl" />
      </Button>
      <Modal isOpen={isOpen} onRequestClose={handleClose}>
        {isDesktop ? (
          <div className="min-w-sm pt-4 px-12 pb-12">
            <ReactQrCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`${pathname}${search}`}
              viewBox="0 0 256 256"
            />
          </div>
        ) : (
          <div className="min-w-xl px-12 pb-12">
            <QrReader
              onResult={(result) => {
                if (result) {
                  toggleOff();
                  navigate(result.getText());
                }
              }}
              constraints={{
                facingMode: 'environment',
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};
