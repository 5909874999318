import { MdAssignment } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Card } from '@cooltra/ui';
import {
  ContractAnnex,
  useCancelContractAnnexMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import messages, { newAnnexTypesMessages } from './messages';

type NewProps = {
  contractId: string;
  annex: ContractAnnex;
};

export const New = ({ contractId, annex: { id, days, type } }: NewProps) => {
  const { formatMessage } = useIntl();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const { mutateAsync, isPending } = useCancelContractAnnexMutation(
    contractId,
    id
  );

  const cancelAnnex = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(
          formatMessage(messages.annexCanceledSuccessfully)
        );
      })
      .catch(() => {
        addErrorNotification();
      });

  return (
    <Card key={id} className="flex items-center p-5 gap-7">
      <div className="flex-1 flex items-center gap-4 pl-2">
        <MdAssignment
          className="text-warning-500 text-xl"
          data-testid="NEW_ANNEX"
        />
        <h4 className="font-semibold text-neutral-800 text-base">
          <FormattedMessage {...messages.annex} />
          <p className="flex items-center gap-1 font-normal text-warning-700 text-sm">
            <FormattedMessage
              {...newAnnexTypesMessages[type]}
              values={{ days }}
            />
          </p>
        </h4>
      </div>
      <div className="flex items-center gap-4">
        <p className="font-normal text-neutral-300 text-sm">
          <FormattedMessage {...messages.pendingToSign} />
        </p>
        <Button
          variant="danger"
          emphasis="medium"
          loading={isPending}
          onClick={cancelAnnex}
        >
          <FormattedMessage {...messages.cancelAnnex} />
        </Button>
      </div>
    </Card>
  );
};
