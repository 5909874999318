import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { DocumentsFormValues } from './documents-form';
import messages from './messages';

export type RemoveIdCard = {
  onRemove: () => void;
};

export const RemoveIdCard = ({ onRemove }: RemoveIdCard) => {
  const { setValues } = useFormContext<DocumentsFormValues>();

  const clearIdCard = () => {
    onRemove();
    setValues({
      idCardFront: '',
      idCardBack: '',
      idCardNumber: '',
      taxIdNumber: '',
      taxIdCountry: '',
    });
  };

  return (
    <Button
      data-testid="ID_CARD_REMOVE_BUTTON"
      type="button"
      size="sm"
      variant="danger"
      onClick={clearIdCard}
    >
      <FormattedMessage {...messages.removeIdCardBtn} />
    </Button>
  );
};
