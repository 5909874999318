import { Card } from '@cooltra/ui';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Geofence, System } from '@cooltra/api';
import { RouterTab } from '@cooltra/navigation';
import { Route, Routes } from 'react-router-dom';

import { SystemCity, SystemImage } from '~/common';

import { Restrictions } from '../Restrictions';
import { BonusZones } from '../BonusZones';

import messages from './messages';

export const SelectedHomeSystem = ({
  system,
  data,
}: {
  system: System;
  data?: Geofence;
}) => (
  <div
    className="w-[40rem] p-4 h-full overflow-auto z-10 shadow-lg"
    data-testid="SELECTED_HOME_SYSTEM"
  >
    <Card className="flex items-center gap-2 p-4">
      <SystemImage system={system} className="w-16 h-16" />
      <div className="flex flex-col">
        <span className="text-sm">
          <SystemCity system={system} />
        </span>
        <span className="text-xs text-neutral-500">
          {data?.updatedAt && (
            <FormattedMessage
              {...messages.lastUpdatedOn}
              values={{
                date: (
                  <FormattedDate
                    value={new Date(data?.updatedAt)}
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                ),
              }}
            />
          )}
        </span>
      </div>
    </Card>
    <div className="mt-10 flex flex-1 shrink-0 gap-10">
      <RouterTab to="restrictions">
        <FormattedMessage {...messages.restriction} />
      </RouterTab>
      <RouterTab to="bonus-zones">
        <FormattedMessage {...messages.bonusZone} />
      </RouterTab>
    </div>
    <Routes>
      <Route path="restrictions" element={<Restrictions system={system} />} />
      <Route path="bonus-zones" element={<BonusZones system={system} />} />
    </Routes>
  </div>
);
