import { defineMessages } from 'react-intl';

export default defineMessages({
  restrictions: {
    defaultMessage:
      '{total, plural, one {# restriction} other {# restrictions}}',
  },
  error: {
    defaultMessage: 'Something went wrong!',
  },
});
