import { Source } from 'react-map-gl';
import { System, useGeofenceQuery } from '@cooltra/api';

import { getGeofenceMapSource } from './get-geofence-map-sources';

export type GeofenceProps = {
  system: System;
  variant?: 'final' | 'original';
};

export const Geofence = ({ system, variant = 'final' }: GeofenceProps) => {
  const { data: geofence } = useGeofenceQuery(system);

  if (!geofence) {
    return <></>;
  }

  const finalGeofence = geofence[variant];
  const geofencesMapSource = getGeofenceMapSource(finalGeofence);

  return (
    <>
      <Source
        id={geofencesMapSource.id}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: geofencesMapSource.data,
        }}
      >
        {geofencesMapSource.layers}
      </Source>
    </>
  );
};
