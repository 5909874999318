import { Layer, Popup, Source } from 'react-map-gl';
import { GeofenceRestriction } from '@cooltra/api';

import { MapLine } from '~/common';

const restrictionFeatureStyles = {
  fillColor: '#D01124',
  fillOutlineColor: '#333',
  lineColor: '#D01124',
};

export type RestrictionFeatureProps = {
  coordinates: [latitude: number, longitude: number][];
  layerId: string;
  isFocused?: boolean;
} & Partial<
  Pick<
    GeofenceRestriction,
    'title' | 'marker' | 'geofenceRestrictionId' | 'enabled'
  >
>;

export const RestrictionFeature = ({
  title,
  marker,
  geofenceRestrictionId,
  coordinates,
  layerId,
  enabled = false,
  isFocused = false,
}: RestrictionFeatureProps) => {
  const sourceFillId = `restriction-fill-${geofenceRestrictionId}`;
  const sourceBordersId = `restriction-border-${geofenceRestrictionId}`;

  if (!enabled && !isFocused) {
    return <></>;
  }

  return (
    <>
      <Source
        id={sourceFillId}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {
                id: geofenceRestrictionId,
                type: 'restriction',
              },
              geometry: {
                coordinates: [coordinates],
                type: 'Polygon',
              },
            },
          ],
        }}
      >
        {isFocused && marker && (
          <Popup
            longitude={marker.longitude}
            latitude={marker.latitude}
            closeButton={false}
            maxWidth="none"
          >
            {title}
          </Popup>
        )}
        <Layer
          interactive
          id={layerId}
          source={sourceFillId}
          type="fill"
          paint={{
            'fill-translate': [0, 0],
            'fill-outline-color': restrictionFeatureStyles.fillOutlineColor,
            'fill-color': restrictionFeatureStyles.fillColor,
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              0,
              12,
              0.1,
              22,
              0.3,
            ],
          }}
        />
      </Source>
      <MapLine
        sourceId={sourceBordersId}
        coordinates={coordinates}
        paint={{
          'line-color': restrictionFeatureStyles.lineColor,
          'line-width': 2,
          'line-dasharray': enabled ? [] : [2, 2],
        }}
      />
    </>
  );
};
