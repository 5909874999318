import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';

import { FormNumberInputField } from '~/common';

import messages from './messages';

export type MileageProps = {
  disabled?: boolean;
};

export const Mileage = ({ disabled = false }: MileageProps) => (
  <FormNumberInputField
    name="mileage"
    id="mileage"
    className="w-60"
    disabled={disabled}
    label={
      <InputLabel htmlFor="mileage">
        <FormattedMessage {...messages.kilometers} />
      </InputLabel>
    }
  />
);
