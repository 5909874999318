import { Card } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { System, useBonusZoneMutation } from '@cooltra/api';
import { useNavigate, useParams } from 'react-router-dom';
import { FormButton } from '@cooltra/form';

import { RouterBackLink } from '~/common';
import { useNotification } from '~/hooks';

import * as ZoneFields from '../../ZoneFields';
import { ZoneMap } from '../../ZoneMap/ZoneMap';
import { BonusZonePreview } from '../BonusZonePreview';
import {
  BonusZoneForm,
  BonusZoneFormValues,
  bonusZoneInitialFormValues,
} from '../../BonusZoneForm';

import messages from './messages';

export const NewBonusZone = () => {
  const { formatMessage } = useIntl();
  const params = useParams<'system'>();
  const system = params.system as System;

  const navigate = useNavigate();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { mutateAsync } = useBonusZoneMutation(system);

  const handleSubbmit = (values: BonusZoneFormValues) =>
    mutateAsync(values)
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        navigate(`/zones/${system}/bonus-zones`);
      })
      .catch(() => addErrorNotification());

  return (
    <div className="container max-w-3xl py-12">
      <div className="mb-9">
        <RouterBackLink to={`/zones/${system}/bonus-zones`}>
          <FormattedMessage {...messages.back} />
        </RouterBackLink>
        <h2 className="text-3xl">
          <FormattedMessage {...messages.bonusZone} />
        </h2>
      </div>
      <BonusZoneForm
        initialValues={bonusZoneInitialFormValues}
        onSubmit={handleSubbmit}
      >
        <Card className="mb-5 p-10">
          <div className="flex flex-col gap-y-6">
            <ZoneFields.Title />
            <ZoneFields.Discount />
            <ZoneFields.Polygon />
            <div className="h-96 w-full rounded-lg overflow-hidden">
              <ZoneMap system={system}>
                <BonusZonePreview />
              </ZoneMap>
            </div>
          </div>
        </Card>
        <div className="flex justify-end">
          <FormButton>
            <FormattedMessage {...messages.createBonusZone} />
          </FormButton>
        </div>
      </BonusZoneForm>
    </div>
  );
};
