import { IntlShape } from 'react-intl';
import { valid } from 'geojson-validation';

import { validateRequired, createSetError } from '~/validation';

import messages from './messages';

export type RestrictionFormValues = {
  title: string;
  polygon: string;
};

type RestrictionFormFieldName = keyof RestrictionFormValues;

type RestrictionFormErrors = Partial<
  Record<RestrictionFormFieldName, string | undefined>
>;

const hasCorrectFormat = (geoJson: any) =>
  geoJson?.features?.[0]?.geometry?.coordinates?.[0] != null ||
  geoJson?.coordinates?.[0] != null;

export const validateRestrictionForm =
  (intl: IntlShape) =>
  ({ title, polygon }: RestrictionFormValues) => {
    const errors: RestrictionFormErrors = {};
    const setError = createSetError(errors);

    const titleErrorMessage = validateRequired(intl, title);
    setError('title', titleErrorMessage);

    try {
      const parsedPolygon = JSON.parse(polygon);
      if (!valid(parsedPolygon) || !hasCorrectFormat(parsedPolygon)) {
        setError('polygon', intl.formatMessage(messages.invalidGeoJson));
      }
    } catch (error) {
      setError('polygon', intl.formatMessage(messages.invalidGeoJson));
    }

    const polygonErrorMessage = validateRequired(intl, polygon);
    setError('polygon', polygonErrorMessage);

    return errors;
  };

export const restrictionInitialFormValues: RestrictionFormValues = {
  title: '',
  polygon: '',
};
