import { FormattedMessage } from 'react-intl';
import { System } from '@cooltra/api';
import { useOperatorsQuery, useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import {
  canAccessHomeSystem,
  isBlocked,
  isOperatorGroup,
  operatorGroups,
} from '~/utils/operators';

import messages from './messages';

export type ServicedByProps = {
  systems?: System[];
};

export const ServicedBy = ({ systems }: ServicedByProps) => {
  const { hasPermission } = useAuthClaimsQuery();
  const { data = [], isLoading } = useOperatorsQuery();
  const operatorGroupIds: string[] = [
    operatorGroups.MECHANIC.operatorGroupId,
    operatorGroups.MECHANIC_MANAGER.operatorGroupId,
    operatorGroups.FLEET_MANAGER.operatorGroupId,
  ];

  return (
    <div data-testid="SERVICED_BY">
      <FormSelectField
        isLoading={isLoading}
        noOptionsMessage={() => <FormattedMessage {...messages.noOperators} />}
        id="service-operator"
        name="servicedBy"
        className="w-full"
        disabled={!hasPermission('write:serviced_by')}
        isClearable
        options={data
          .filter(isBlocked(false))
          .filter(canAccessHomeSystem(systems ? systems : []))
          .filter(isOperatorGroup(operatorGroupIds))
          .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
          .map(({ externalId, name }) => ({
            label: name,
            value: externalId,
          }))}
        label={
          <InputLabel htmlFor="service-operator">
            <FormattedMessage {...messages.operator} />
          </InputLabel>
        }
      />
    </div>
  );
};
