import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import messages from './messages';

export const NewBonusZoneLink = () => {
  const { hasPermission } = useAuthClaimsQuery();

  return hasPermission('write:bonus_zones') ? (
    <RouterButton emphasis="high" size="sm" to="new">
      <FormattedMessage {...messages.newBonusZone} />
    </RouterButton>
  ) : null;
};
