import { useState } from 'react';
import {
  useContractPaymentsQuery,
  ContractPayment,
} from '@cooltra/station-based-api';

import { usePolling } from '~/hooks';

export type UsePayTicketPollingProps = {
  contractId: string;
  ticketId: string;
  onSettle: () => void;
};

const getIsPaymentPaid = (
  contractPayments: ContractPayment[] | undefined,
  ticketId: string
) => {
  const ticket = contractPayments?.find((t) => t.id === ticketId);
  return Boolean(ticket?.status === 'PAID');
};

export const usePayTicketPolling = ({
  onSettle,
  contractId,
  ticketId,
}: UsePayTicketPollingProps) => {
  const [isStripePoolingActive, setIsStripePollingActive] = useState(false);
  const { data: contractPayments, refetch } =
    useContractPaymentsQuery(contractId);

  const isPaymentPaid = getIsPaymentPaid(contractPayments, ticketId);

  const { isPolling } = usePolling({
    fn: refetch,
    onSettle: onSettle,
    maxAttempts: 5,
    interval: !isPaymentPaid && isStripePoolingActive ? 500 : 0,
  });

  return {
    isPolling,
    setIsStripePollingActive,
  };
};
