import { IntlShape } from 'react-intl';
import { System, Location, CreateLocationPayload } from '@cooltra/api';
import { getKeys } from '@cooltra/utils';
import { FormErrors } from '@cooltra/form';

import {
  validateRequired,
  validateRequiredCheckboxGroup,
  createSetError,
} from '~/validation';

export type CreateLocationFormValues = Pick<Location, 'name'> & {
  systems: {
    [key in System]: boolean;
  };
};

export const validateCreateLocationForm =
  (intl: IntlShape) =>
  (values: CreateLocationFormValues): FormErrors<CreateLocationFormValues> => {
    const errors: FormErrors<CreateLocationFormValues> = {};
    const setError = createSetError(errors);

    const nameErrorMessage = validateRequired(intl, values.name);
    setError('name', nameErrorMessage);

    const systemsErrorMessage = validateRequiredCheckboxGroup(
      intl,
      values.systems
    );
    setError('systems', systemsErrorMessage);
    return errors;
  };

export const locationFormInitialValues: CreateLocationFormValues = {
  name: '',
  systems: {
    Barcelona: false,
    Berlin: false,
    Munich: false,
    Lisbon: false,
    Madrid: false,
    Malaga: false,
    Mallorca: false,
    Milan: false,
    Paris: false,
    Rome: false,
    Valencia: false,
    Zurich: false,
    Turin: false,
    Seville: false,
    Amsterdam: false,
    Breda: false,
    Brussels: false,
    Den_bosch: false,
    The_hague: false,
    Enschede: false,
    Groningen: false,
    Haarlem: false,
    Nijmegen: false,
    Rotterdam: false,
    Tilburg: false,
    Zwolle: false,
    Hilversum: false,
    Antwerp: false,
  },
};

export const formatLocationFormValues = (
  values: CreateLocationFormValues
): CreateLocationPayload => ({
  ...values,
  systems: getKeys(values.systems).filter((system) => values.systems[system]),
});
