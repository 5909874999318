import { TimelineItem } from '@cooltra/ui';
import { OperatorLatestApprovalReview } from '@cooltra/api';
import { RouterLink } from '@cooltra/navigation';

import { DateTime, UserApprovalReviewStateBadge } from '~/common';

export const ReviewTimelineItem = ({
  userId,
  name,
  createdAt,
  type,
  comment,
}: OperatorLatestApprovalReview) => {
  return (
    <TimelineItem
      timelineClassName="translate-y-2"
      contentClassName="w-full pb-10 pr-5"
      header={
        <div className="w-full flex items-center justify-between">
          <RouterLink
            className="font-semibold text-base"
            target="_blank"
            to={`/users/${userId}/account/overview`}
          >
            {name}
          </RouterLink>
          <DateTime
            date={createdAt}
            dateClassName="text-neutral-400 font-medium"
            timeClassName="text-neutral-400"
          />
        </div>
      }
    >
      <div className="pt-1.5">
        <UserApprovalReviewStateBadge state={type} />
        <span className="block italic pt-1 text-neutral-400 text-sm">
          {comment}
        </span>
      </div>
    </TimelineItem>
  );
};
