import { Rental } from '@cooltra/api';
import { Card, Divider, TimelineItem } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { DropOff, PickUp, RentalDuration, RentalMap } from '~/common';

import { RentalDropOffMap } from './DropOffMap';
import messages from './messages';

export type RentalInfoProps = {
  rental: Rental;
};

export const RentalInfo = ({ rental }: RentalInfoProps) => (
  <Card className="overflow-hidden">
    <div className="grid grid-cols-3">
      <div className="col-span-2">
        <div className="flex flex-col py-4 pl-4 gap-4">
          <div className="h-96 rounded-lg overflow-hidden">
            <RentalMap rental={rental} id="traffic-offense-rental-map" />
          </div>
          {rental.dropOff && (
            <div className="h-96 rounded-lg overflow-hidden">
              <RentalDropOffMap
                dropOff={rental.dropOff}
                system={rental.system}
              />
            </div>
          )}
        </div>
      </div>
      <div className="p-10 flex flex-col gap-8 text-neutral-800">
        <div className="flex justify-between">
          <FormattedMessage tagName="span" {...messages.licensePlate} />
          <span>{rental.vehicle.licensePlate}</span>
        </div>
        {rental.dropOff && (
          <div className="flex justify-between">
            <FormattedMessage tagName="span" {...messages.duration} />
            <RentalDuration
              startDate={rental.pickUp.createdAt}
              finishDate={rental.dropOff?.createdAt}
            />
          </div>
        )}
        {rental.dropOff && (
          <>
            <Divider className="my-4" />
            <div>
              <TimelineItem
                header={
                  <span className="font-semibold">
                    <FormattedMessage {...messages.pickUp} />
                  </span>
                }
                timelineClassName="translate-y-2.5"
              >
                <div className="mt-1 pb-20">
                  <PickUp pickUp={rental.pickUp} />
                </div>
              </TimelineItem>
              <TimelineItem
                header={
                  <span className="font-semibold">
                    <FormattedMessage {...messages.dropOff} />
                  </span>
                }
                isLast
                timelineClassName="translate-y-2.5"
                className="relative"
                contentClassName={rental.dropOff.parkingPhoto && 'pl-16 ml-2'}
              >
                <div className="mt-1">
                  <DropOff dropOff={rental.dropOff} />
                </div>
              </TimelineItem>
            </div>
          </>
        )}
      </div>
    </div>
  </Card>
);
