import { FormattedMessage, useIntl } from 'react-intl';
import { useOperatorsQuery, useAuthClaimsQuery } from '@cooltra/auth-api';
import { System } from '@cooltra/api';
import { useField, FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { canAccessHomeSystem, isBlocked } from '~/utils/operators';

import messages from './messages';

export const WithOperator = () => {
  const { formatMessage } = useIntl();

  const { data: claims } = useAuthClaimsQuery();
  const accessibleSystems = claims?.systems || [];

  const { value: systems } = useField<System[]>('system');
  const { data = [], isLoading } = useOperatorsQuery();

  return (
    <FormSelectField
      isLoading={isLoading}
      noOptionsMessage={() => <FormattedMessage {...messages.noOperators} />}
      placeholder={formatMessage(messages.all)}
      id="with-operator"
      name="withOperator"
      isClearable
      options={data
        .filter(isBlocked(false))
        .filter(
          canAccessHomeSystem(systems.length ? systems : accessibleSystems)
        )
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        .map(({ externalId, name }) => ({
          label: name,
          value: externalId,
        }))}
      label={
        <InputLabel htmlFor="with-operator">
          <FormattedMessage {...messages.withOperator} />
        </InputLabel>
      }
    />
  );
};
