import { defineMessages } from 'react-intl';

export default defineMessages({
  driverLicenseExpirationDate: {
    defaultMessage: 'Expiration date',
  },
  driverLicenseCategory: {
    defaultMessage: 'Which scooter can the user drive?',
  },
  smScooter: {
    defaultMessage: 'Small',
  },
  lgScooter: {
    defaultMessage: 'Large',
  },
  xlScooter: {
    defaultMessage: 'Extra large',
  },
  driverLicenseCountry: {
    defaultMessage: 'Issuing country',
  },
});
