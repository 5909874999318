import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';

import {
  ManualAdjustmentButton,
  ManualAdjustmentForm,
} from '../../ManualAdjustmentForm';
import { PayTicketButton } from '../../PayTicketButton/PayTicketButton';

export type ContractPaymentsButtonsProps = {
  hasPendingPayments: boolean;
  contract: Contract;
  payment?: ContractPayment;
  showChargeButton?: boolean;
  disabled?: boolean;
};

export const ContractPaymentsButtons = ({
  contract,
  hasPendingPayments,
  showChargeButton = true,
  disabled = false,
}: ContractPaymentsButtonsProps) => {
  const [isManualAdjustmentOpen, { toggleOff, toggleOn }] = useToggle();

  const enabled =
    (contract.status === 'DRAFT' ||
      contract.status === 'BOOKED' ||
      contract.status === 'ACTIVE' ||
      contract.status === 'CLOSED') &&
    !isManualAdjustmentOpen;

  return (
    <div className="justify-end flex flex-col gap-8 relative items-end">
      {isManualAdjustmentOpen && <ManualAdjustmentForm close={toggleOff} />}
      <div className="flex flex-row gap-2">
        <ManualAdjustmentButton
          disabled={disabled || !enabled}
          onClick={toggleOn}
        />
        {showChargeButton && (
          <PayTicketButton
            contract={contract}
            disabled={disabled || !hasPendingPayments}
          />
        )}
      </div>
    </div>
  );
};
