import { Contract } from '@cooltra/station-based-api';
import { FormattedMessage } from 'react-intl';

import { ClickAndRideLogo, ContractBadge } from '~/common';
import { useContractFlags } from '~/hooks';

import { PaidAmount } from '../PaidAmount/PaidAmount';
import { DepositAmount } from '../DepositAmount/DepositAmount';
import { PendingAmount } from '../PendingAmount/PendingAmount';
import { SignAnnexButton } from '../SignAnnexButton/SignAnnexButton';
import { CloseContractButton } from '../CloseContractButton/CloseContractButton';
import { NoShowContractButton } from '../NoShowContractButton/NoShowContractButton';
import { CancelContractButton } from '../CancelContractButton/CancelContractButton';
import { DiscardContractButton } from '../DiscardContractButton/DiscardContractButton';
import { ActivateContractButton } from '../ActivateContractButton/ActivateContractButton';
import { SendContractByEmailButton } from '../SendContractByEmailButton/SendContractByEmailButton';

import messages from './messages';

export type ContractNavbarProps = {
  contract: Contract;
};

export const ContractNavbar = ({ contract }: ContractNavbarProps) => {
  const {
    contractNumber,
    status,
    isMigrated,
    paidAmount,
    pendingAmount,
    hasClickAndRide,
    annexes,
  } = contract;

  const { hasBeenModifiedInEtendo } = useContractFlags();

  const contractPendingAnnex = annexes?.find(
    ({ status }) => status === 'PENDING_SIGNED' || status === 'PENDING_PAY'
  );

  return (
    <div
      data-testid="CONTRACT_TOPBAR"
      className="bg-neutral-0 shadow-sm relative z-10 h-32 xl:h-20 px-4 xl:px-8"
    >
      <div className="flex flex-row justify-between items-center gap-x-4 h-full">
        <div className="h-full flex flex-col xl:flex-row xl:items-center justify-center gap-x-4 gap-y-4">
          <h2 className="font-semibold text-2xl text-neutral-700">
            {contractNumber}
          </h2>
          <div className="flex gap-x-4 items-center">
            <ContractBadge status={status} />
            {hasClickAndRide && <ClickAndRideLogo className="h-5" />}
          </div>
        </div>
        {isMigrated ? (
          <p className="text-neutral-500 text-md">
            <FormattedMessage {...messages.migratedFromEtendo} />
          </p>
        ) : hasBeenModifiedInEtendo ? (
          <p className="text-neutral-500 text-md">
            <FormattedMessage {...messages.continueInEtendo} />
          </p>
        ) : status === 'CANCELED' || status === 'NO_SHOW' ? null : (
          <div className="flex flex-col xl:flex-row xl:items-center justify-center gap-y-4 h-full gap-x-10">
            <div className="flex gap-x-4 order-1 xl:order-2">
              <NoShowContractButton contract={contract} />
              <CancelContractButton contract={contract} />
              {contractNumber && <DiscardContractButton contract={contract} />}
              <ActivateContractButton contract={contract} />
              {contractPendingAnnex && (
                <SignAnnexButton
                  contractId={contract.contractId}
                  annexId={contractPendingAnnex.id}
                />
              )}
              <SendContractByEmailButton contract={contract} />
              <CloseContractButton contract={contract} />
            </div>
            <div className="flex gap-x-10 order-2 xl:order-1 justify-end">
              <PaidAmount amount={paidAmount} />
              <DepositAmount contract={contract} />
              <PendingAmount amount={pendingAmount} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
