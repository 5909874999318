import { System, ThreeStateValue, IncidentSort } from '@cooltra/api';

export type IncidentsFiltersFormValues = {
  system: System[];
  operatorGroupId: string;
  operatorId: string;
  open: ThreeStateValue;
  sort: IncidentSort;
};

export const incidentsFiltersInitialValues: IncidentsFiltersFormValues = {
  system: [],
  operatorGroupId: '',
  operatorId: '',
  open: 'true',
  sort: 'updatedAt.desc',
};
