import { Card } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { Mileage, LevelSelector } from '../../../../../ChecksFields';

import messages from './messages';

export const Checks = () => {
  return (
    <>
      <h1 className="text-xl text-neutral-700 mb-6">
        <FormattedMessage {...messages.checks} />
      </h1>
      <Card className="p-8">
        <div className="grid grid-cols-2 gap-8 mb-6">
          <Mileage />
          <LevelSelector levels={8} name="fuelLevel" />
        </div>
      </Card>
    </>
  );
};
