import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';

import { MapView } from '~/hooks';

import messages from './messages';

export type OpsMapSwitchViewProps = {
  mapView: MapView;
  updateMapView: (mapView: MapView) => void;
};

export const OpsMapSwitchView = ({
  mapView,
  updateMapView,
}: OpsMapSwitchViewProps) => {
  const handleOnSelectBatteryView = () => {
    updateMapView('by-battery');
  };
  const handleOnSelectStatusView = () => {
    updateMapView('by-status');
  };

  const isBatteryView = mapView === 'by-battery';
  const isStatusView = mapView === 'by-status';

  return (
    <div className="w-full flex justify-center absolute top-20">
      <div className="flex gap-2 p-2 bg-neutral-0 rounded-xl shadow">
        <Button
          variant="primary"
          role="switch"
          aria-checked={isStatusView}
          emphasis={isStatusView ? 'high' : 'low'}
          size="sm"
          onClick={handleOnSelectStatusView}
        >
          <FormattedMessage {...messages.byStatus} />
        </Button>
        <Button
          variant="primary"
          role="switch"
          aria-checked={isBatteryView}
          emphasis={isBatteryView ? 'high' : 'low'}
          size="sm"
          onClick={handleOnSelectBatteryView}
        >
          <FormattedMessage {...messages.byBattery} />
        </Button>
      </div>
    </div>
  );
};
