import { BonusZone, useBonusZoneToggleMutation } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Badge, Card, ToggleField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEventHandler, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Amount } from '~/common';
import { useNotification } from '~/hooks';

import { useFocusedZone } from '../../FocusedZone';

import messages from './messages';

export const BonusZoneCard = ({
  title,
  enabled,
  discount,
  bonusZoneId,
  system,
}: BonusZone) => {
  const { formatMessage } = useIntl();
  const { setBonusZoneIdFocused } = useFocusedZone();
  const { hasPermission } = useAuthClaimsQuery();

  const { addSuccessNotification, addErrorNotification } = useNotification();
  const queryClient = useQueryClient();

  const [bonusZoneEnabled, setBonusZoneEnabled] = useState(enabled);
  const { mutateAsync } = useBonusZoneToggleMutation(system, bonusZoneId);

  const disableBonusZone = () =>
    mutateAsync({ enabled: false })
      .then(() =>
        addSuccessNotification(formatMessage(messages.disablingSuccess))
      )
      .catch(() => {
        setBonusZoneEnabled(true);
        addErrorNotification();
      })
      .finally(() =>
        queryClient.invalidateQueries({ queryKey: ['bonus-zones', system] })
      );

  const enableBonusZone = () =>
    mutateAsync({ enabled: true })
      .then(() =>
        addSuccessNotification(formatMessage(messages.enablingSuccess))
      )
      .catch((error) => {
        setBonusZoneEnabled(false);
        switch (getErrorStatus(error)) {
          case 409:
            addErrorNotification(formatMessage(messages.collisionError));
            break;
          default:
            addErrorNotification();
            break;
        }
      })
      .finally(() =>
        queryClient.invalidateQueries({ queryKey: ['bonus-zones', system] })
      );

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { checked } = event.target;

    if (checked) {
      enableBonusZone();
    } else {
      disableBonusZone();
    }

    setBonusZoneEnabled(checked);
  };

  const handleOnMouseOver = () => {
    setBonusZoneIdFocused(bonusZoneId);
  };
  const handleOnMouseLeave = () => {
    setBonusZoneIdFocused(undefined);
  };

  return (
    <Card
      className="px-4 py-6"
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      data-testid={`BONUS_ZONE_${bonusZoneId}`}
    >
      <div className="flex items-center justify-between">
        <div className="flex-1 text-ellipsis overflow-hidden">
          <span className="text-neutral-800 font-semibold text-sm">
            {title}
          </span>
        </div>
        <Amount className="px-4" value={discount} currency="EUR" />
        {bonusZoneEnabled ? (
          <Badge variant="success">
            <FormattedMessage {...messages.active} />
          </Badge>
        ) : (
          <Badge>
            <FormattedMessage {...messages.inactive} />
          </Badge>
        )}
        {hasPermission('switch:bonus_zones') && (
          <div className="pl-4">
            <ToggleField
              data-testid="BONUS_ZONE_TOGGLE"
              id={`toggle-bonus-zone-${bonusZoneId}`}
              checked={bonusZoneEnabled}
              onChange={handleOnChange}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
