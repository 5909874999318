import { Geofence, MultiPolygon } from '@cooltra/api';
import { Layer } from 'react-map-gl';

import { geofenceLayer } from './layer';

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element;
  layerId: string;
  data: any[];
};

export const getGeofenceMapSource = (
  geofence: MultiPolygon
): GeofencesMapSource => ({
  id: 'geofence',
  layerId: geofenceLayer.id as string,
  data: [
    {
      type: 'Feature',
      properties: {},
      geometry: geofence,
    },
  ],
  layers: <Layer key="geofenceLayer" {...geofenceLayer} />,
});

export const getGeofenceMapSourceWithHomeSystem = (
  geofence: Geofence,
  homeSystem: string
): GeofencesMapSource => {
  const geofenceSourceId = `geofence-${homeSystem}`;
  const layerId = `${geofenceLayer.id}-${homeSystem}`;
  const geofenceLayerObjectData = {
    ...geofenceLayer,
    source: geofenceSourceId,
    id: layerId,
  };

  return {
    id: geofenceSourceId,
    layerId,
    data: [
      {
        type: 'Feature',
        properties: {},
        geometry: geofence.final,
      },
    ],
    layers: <Layer key="geofenceLayer" {...geofenceLayerObjectData} />,
  };
};
