import { System } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@cooltra/ui';
import { MdLocationOn } from 'react-icons/md';

import { Pair } from '../Pair';

import { SystemCity } from './SystemCity';
import { labels } from './messages';

export type SystemPairProps = {
  system: System | undefined | null;
};

export const SystemPair = ({ system }: SystemPairProps) => (
  <div>
    <Pair.Label className="mb-1.5">
      <FormattedMessage {...labels.system} />
    </Pair.Label>
    {system ? (
      <Pair.Value className="flex gap-1">
        <Icon className="text-neutral-300">
          <MdLocationOn />
        </Icon>
        <SystemCity system={system} />
      </Pair.Value>
    ) : (
      <Pair.ValueLoading />
    )}
  </div>
);
