import { defineMessages } from 'react-intl';

export default defineMessages({
  bonusZones: {
    defaultMessage: '{total, plural, one {# bonus zone} other {# bonus zones}}',
  },
  error: {
    defaultMessage: 'Something went wrong!',
  },
});
