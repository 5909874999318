import { defineMessages } from 'react-intl';

export default defineMessages({
  active: {
    defaultMessage: 'Active',
  },
  inactive: {
    defaultMessage: 'Inactive',
  },
  disablingSuccess: {
    defaultMessage: 'The bonus zone was successfully disabled',
  },
  enablingSuccess: {
    defaultMessage: 'The bonus zone was successfully enabled',
  },
  collisionError: {
    defaultMessage:
      'The bonus zone collides with an active bonus zone, restriction or is outside the geofence',
  },
});
