import { RouterButton } from '@cooltra/navigation';
import { FormattedMessage } from 'react-intl';
import { useAuthClaimsQuery } from '@cooltra/auth-api';

import messages from './messages';

export const NewRestrictionLink = () => {
  const { hasPermission } = useAuthClaimsQuery();

  return hasPermission('write:geofence_restrictions') ? (
    <RouterButton emphasis="high" size="sm" to="new">
      <FormattedMessage {...messages.newRestriction} />
    </RouterButton>
  ) : null;
};
