import { System, useGeofenceQuery } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { useParams } from 'react-router-dom';
import { Placeholder } from '@cooltra/ui';

import { isTestingEnv } from '~/utils/e2e';

import { SelectedHomeSystem } from '../SelectedHomeSystem/SelectedHomeSystem';
import { HomeSystemSelector } from '../HomeSystemSelector/HomeSystemSelector';
import { ZonesMap } from '../ZonesMap';
import { FocusedZoneProvider } from '../FocusedZone';

import './zones.css';

export const Zones = () => {
  const params = useParams<'system'>();
  const system = params.system as System;
  const { hasPermission } = useAuthClaimsQuery();
  const { data } = useGeofenceQuery(system);

  if (
    !hasPermission('read:bonus_zones') &&
    !hasPermission('read:geofence_restrictions')
  ) {
    return <></>;
  }

  return (
    <div data-testid="ZONES">
      <HomeSystemSelector />
      <div className="zones flex">
        <FocusedZoneProvider>
          <SelectedHomeSystem system={system} data={data} />
          {isTestingEnv() ? (
            <Placeholder className="h-full w-full rounded-none" />
          ) : (
            <ZonesMap system={system} />
          )}
        </FocusedZoneProvider>
      </div>
    </div>
  );
};
