import { Card, Placeholder, MultiSelect } from '@cooltra/ui';
import { useLocationsQuery, System } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { RouterButton, RouterLink } from '@cooltra/navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import {
  consecutiveNumbers,
  getArrayParam,
  stringifyParams,
} from '@cooltra/utils';
import { useLocation } from 'react-router-dom';

import { ErrorPage, Four0Three, useSystemOptions } from '~/common';
import { useNavigateWithQueryParams } from '~/hooks';

import messages from './messages';

export const LocationsList = () => {
  const { search } = useLocation();
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const initialSystems = getArrayParam(search, 'system');

  const { formatMessage } = useIntl();

  const [systems, setSystems] = useState<string[]>(initialSystems);
  const options = useSystemOptions();

  const { hasPermission } = useAuthClaimsQuery();

  const { isError, error, data } = useLocationsQuery({
    system: systems as System[],
  });

  useEffect(() => {
    navigateWithQueryParams({ system: systems });
  }, [navigateWithQueryParams, systems]);

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      className="container min-w-screen-sm max-w-2xl py-12"
      data-testid="LOCATIONS"
    >
      <div className="mb-10 w-full">
        <h2 className="text-neutral-1000 mb-11">
          <FormattedMessage {...messages.title} />
        </h2>
        <div className="flex justify-between">
          {options.length > 1 && (
            <MultiSelect
              aria-label={formatMessage(messages.allSystems)}
              isClearable
              className="w-56"
              value={systems}
              onChange={(options) =>
                setSystems(options.map(({ value }) => value))
              }
              options={options}
              placeholder={formatMessage(messages.allSystems)}
            />
          )}
          {hasPermission('write:locations') && (
            <RouterButton to="new" emphasis="high">
              <FormattedMessage {...messages.addNewButton} />
            </RouterButton>
          )}
        </div>
      </div>
      <ul className="flex flex-col gap-2.5">
        {!data &&
          consecutiveNumbers(8).map((i) => (
            <li key={`location-list-placeholder-${i}`}>
              <Card className="h-16 flex items-center px-6">
                <Placeholder className="h-2 w-60" />
              </Card>
            </li>
          ))}
        {data &&
          data
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ locationId, name, vehiclesCount }) => (
              <li key={locationId}>
                <Card className="h-16 flex items-stretch px-6 gap-14">
                  <div className="flex w-full items-center">
                    <span className="flex-1 text-sm font-semibold">{name}</span>
                    <RouterLink
                      variant="muted"
                      className="px-1 text-sm"
                      to={`/vehicles?${stringifyParams({
                        location: locationId,
                        system: systems,
                      })}`}
                    >
                      <FormattedMessage
                        values={{ count: vehiclesCount }}
                        {...messages.count}
                      />
                    </RouterLink>
                  </div>
                  {hasPermission('write:locations') && (
                    <RouterLink to={locationId} className="text-sm self-center">
                      <FormattedMessage {...messages.edit} />
                    </RouterLink>
                  )}
                </Card>
              </li>
            ))}
      </ul>
    </div>
  );
};
