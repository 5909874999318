import { InvoiceCategory, InvoiceLegalEntity, System } from '@cooltra/api';
import { IntlShape } from 'react-intl';

import { getSystemLegalEntity } from '~/utils/system';
import {
  createSetError,
  validateAmount,
  validateNoSpecialCharacters,
  validateRequired,
} from '~/validation';
import { getLegalEntityDefaultTaxRate } from '~/utils/legal-entity';

import messages from './messages';

export type ChargeUserFormValues = {
  category: InvoiceCategory;
  legalEntity: InvoiceLegalEntity;
  item: string;
  amount: string;
  taxRate: string;
};

export const createChargeUserInitialFormValues = (
  system: System
): ChargeUserFormValues => {
  const systemLegalEntity = getSystemLegalEntity(system);
  return {
    category: '' as InvoiceCategory,
    legalEntity: systemLegalEntity,
    item: '',
    amount: '',
    taxRate: getLegalEntityDefaultTaxRate(systemLegalEntity),
  };
};

type ChargeUserFormFieldName = keyof ChargeUserFormValues;

type ChargeUserFormErrors = Partial<
  Record<ChargeUserFormFieldName, string | undefined>
>;

export const validateChargeUserForm =
  (intl: IntlShape) => (values: ChargeUserFormValues) => {
    const errors: ChargeUserFormErrors = {};

    const setError = createSetError(errors);

    setError('legalEntity', validateRequired(intl, values.legalEntity));

    setError('category', validateRequired(intl, values.category));

    setError('item', validateRequired(intl, values.item));

    setError('item', validateNoSpecialCharacters(intl, values.item));

    setError(
      'amount',
      Number(values.amount) < 0.5
        ? intl.formatMessage(messages.minAmountError)
        : undefined
    );

    setError(
      'amount',
      Number(values.amount) > 2500
        ? intl.formatMessage(messages.maxAmountError)
        : undefined
    );

    setError('amount', validateAmount(intl, values.amount));
    setError('amount', validateRequired(intl, values.amount));

    setError('taxRate', validateRequired(intl, values.taxRate));

    return errors;
  };
