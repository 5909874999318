import { FormattedMessage } from 'react-intl';
import { Icon } from '@cooltra/ui';
import { MdOutlineChevronLeft } from 'react-icons/md';
import { RouterBaseLink } from '@cooltra/navigation';
import { stringifyParams } from '@cooltra/utils';

import { useVehiclesFilters } from '~/libs/vehicles-filters';

import messages from './messages';

export const GoToListViewButton = () => {
  const { values } = useVehiclesFilters();

  return (
    <RouterBaseLink
      className="bg-neutral-0 shadow rounded-md h-11 pl-3 pr-5 text-neutral-500 text-base font-semibold"
      to={`/vehicles?${stringifyParams(values)}`}
    >
      <Icon className="text-sm mr-1">
        <MdOutlineChevronLeft />
      </Icon>
      <FormattedMessage {...messages.listView} />
    </RouterBaseLink>
  );
};
